import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import {
  bold,
  borderInput,
  cardBorder,
  flex,
  hidden,
} from "./contact.module.css"

export const Head = () => <Seo title="Contact" slug="/contact" />

const ContactPage = () => {
  return (
    <Layout content="page">
      <h1>Contactez-nous</h1>
      <p>
        Nous sommes à votre disposition pour répondre à vos questions et vous
        accompagner dans le développement de votre présence en ligne.
      </p>
      <h2>Utilisez le formulaire ci-dessous :</h2>
      <form
        className={flex}
        name="contact"
        method="post"
        action="/confirmation"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="form-name" value="contact" />
        <label className={hidden}>
          Don’t fill this out if you’re human: <input name="bot-field" />
        </label>

        <label htmlFor="name">Votre nom</label>
        <input
          className={`${borderInput} ${cardBorder}`}
          type="text"
          id="name"
          name="nom"
          required
        />

        <label htmlFor="email">Votre e-mail</label>
        <input
          className={`${borderInput} ${cardBorder}`}
          type="email"
          id="email"
          name="email"
          required
        />

        <label htmlFor="phone">Votre numéro de téléphone</label>
        <input
          className={`${borderInput} ${cardBorder}`}
          type="tel"
          id="phone"
          name="telephone"
          required
        />

        <label htmlFor="restaurant">Nom de votre restaurant</label>
        <input
          className={`${borderInput} ${cardBorder}`}
          type="text"
          id="restaurant"
          name="restaurant"
          required
        />

        <label htmlFor="service">Quels services vous intéressent ?</label>
        <select
          className={`${borderInput} ${cardBorder}`}
          id="service"
          name="service"
          multiple
          required
        >
          <option value="site-vitrine">Création de site vitrine</option>
          <option value="reservation">Plateforme de réservation</option>
          <option value="ecommerce">
            Site e-commerce pour ventes à emporter
          </option>
          <option value="reputation">
            Amélioration de la réputation en ligne
          </option>
          <option value="autre">Autre</option>
        </select>

        <label htmlFor="message">Votre message</label>
        <textarea
          className={`${borderInput} ${cardBorder}`}
          id="message"
          name="message"
          rows="10"
          required
        ></textarea>

        <label>
          <small>
            <input
              type="checkbox"
              name="confidentialite"
              value="Oui"
              required
            />{" "}
            J'ai lu et j'accepte la politique de confidentialité de Paris
            asiatique (<span className={bold}>obligatoire</span>)
          </small>
        </label>

        <button className={cardBorder} type="submit">
          Envoyer
        </button>
      </form>

      <p>
        <small>
          <span className={bold}>Politique de confidentialité</span> – Les
          informations recueillies sur ce formulaire sont enregistrées dans un
          fichier informatisé par Paris asiatique pour la gestion de sa
          clientèle. Elles sont conservées pendant un an et sont destinées à
          l’éditeur du service. Conformément à la loi « informatique et libertés
          », vous pouvez exercer votre droit d’accès aux données vous concernant
          et les faire rectifier en nous contactant sur{" "}
          <a
            href="mailto:joachim@parisasiatique.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            joachim@parisasiatique.com
          </a>
          .
        </small>
      </p>
    </Layout>
  )
}

export default ContactPage
